// @flow
/* eslint import/no-webpack-loader-syntax: 0 */

import React, { Component } from "react";
import PDFWorker from "worker-loader!pdfjs-dist/lib/pdf.worker";

import {
  PdfLoader,
  PdfHighlighter,  
  Highlight,
  Popup,
  AreaHighlight,
  setPdfWorker
} from "react-pdf-highlighter";

import testHighlights from "./test-highlights";
import Add from "./Add";

import Spinner from "./Spinner";

import type {
  T_Highlight,
  T_NewHighlight
} from "react-pdf-highlighter/src/types";

import "./style/App.css";

setPdfWorker(PDFWorker);

const getNextId = () => String(Math.random()).slice(2);

// const parseIdFromHash = () =>
//   document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  // document.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  (comment !== undefined && comment.text) ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;


class App extends Component<Props, State> {  

  state = {
    url: window.getPDFurl().a,
    pdfhighlights: window.getPDFHighlights(),
    count: 1,
    display: false
  };

    
  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight =  window.getPDFhighlightValue();// getHighlightById(parseIdFromHash());

    if (highlight != null) {
      this.scrollViewerTo(highlight);      
    }
  };

  // get the record details
  componentDidMount() {      
    const self = this;
    window.getPDFhighlight().registerListener(function(val) {
      console.log("Scroll to view");
      self.scrollToHighlightFromHash();
    });
    

    window.getPDFurl().registerListener(function(val) {
      self.setState({
        display: true,
        url: window.getPDFurl().a,
      });
    });
    
    window.getPDFhighlightsObj().registerListener(function(val) {   
      // reload the page to show the changed highlights     
      self.setState({
        pdfhighlights: window.getPDFhighlightsObj().highlights
      });
      //setCount(count+1);
    });

  }

  resetHighlights = () => {
    let highlights = this.setState.pdfhighlights;    

    this.setState({
      pdfhighlights: []
    });
    
  };
   

  addHighlight = (highlight: T_NewHighlight) => {    
  
    const  hl = [...this.state.pdfhighlights, { ...highlight, id: getNextId() }];
    window.savePDFHighlights(hl);    

    this.setState({
      pdfhighlights: hl
    });
    // setCount(count+1);
  }

  updateHighlight = (highlightId: string, position: Object, content: Object) => {
    console.log("Updating highlight", highlightId, position, content);
    
    const hl = this.state.pdfhighlights.map(h => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest
            }
          : h;
      })      
    ;    

    window.savePDFHighlights(hl);
    this.setState({
      pdfhighlights: hl
    });
    // setCount(count+1);
  }        

  render() {
    const { url, pdfhighlights, display } = this.state;

    return (      
      <div id="pdfOuter" className="pdf-outer" tabIndex="0">                
        <div className="pdf-inner">
        {(() => {
          if(display) return <PdfLoader url={url} beforeLoad={<Spinner />}>
              {pdfDocument => (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  enableAreaSelection={event => event.altKey}
                  onScrollChange={resetHash}
                  // pdfScaleValue="page-width"
                  scrollRef={scrollTo => {
                    this.scrollViewerTo = scrollTo;

                    this.scrollToHighlightFromHash();
                  }}
                  onSelectionFinished={(
                    position,
                    content,
                    hideTipAndSelection,
                    transformSelection
                  ) => (                  
                    <Add                    
                      onConfirm={comment => {
                          this.addHighlight({ content, position, comment });
                          hideTipAndSelection();
                      }}
                    />
                  )}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    const isTextHighlight = !Boolean(
                      highlight.content && highlight.content.image
                    );

                    const component = isTextHighlight ? (
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    ) : (
                      <AreaHighlight
                        isScrolledTo={isScrolledTo}
                        highlight={highlight}
                        onChange={boundingRect => {
                          this.updateHighlight(
                            highlight.id,
                            { boundingRect: viewportToScaled(boundingRect) },
                            { image: screenshot(boundingRect) }
                          );
                        }}
                      />
                    );

                    return (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        onMouseOver={popupContent =>
                          setTip(highlight, highlight => popupContent)
                        }
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={pdfhighlights}
                />
              )}
            </PdfLoader>
          })()}
        </div>        
      </div>
    );  
  }
}

export default App;
