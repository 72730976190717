// @flow

import React, { Component } from "react";

import "./style/App.css";

const Add = ({onConfirm}) => {  
      
    return (
      <div className="Tip">        
          <div
            className="Tip__compact"
            onClick={() => {
              onConfirm();              
            }}
          >
            Save selected text
          </div>        
      </div>
    );  
}

export default Add;